import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.min.css"

import useStoryblok from "../lib/storyblok"
import LanguageProvider from "../i18n/LanguageProvider"

import Layout from "../components/global/layout/Layout"
import Seo from "../components/global/seo/Seo"
import DynamicComponent from "../components/DynamicComponent"
import PanelWrapper from "../components/internal/panelWrapper/PanelWrapper"
import StoryblokImage from "../components/internal/storyblokImage/StoryblokImage"
import MediaHero from "../components/internal/mediaHero/MediaHero"
import NarrowContainer from "../components/internal/narrowContainer/NarrowContainer"


import LinkIcon from "../../static/images/icon_link.svg"
import LinkedinIcon from "../../static/images/icon_linkedin.svg"
import { linkShare, linkedinShare } from "../utils/socialShare"




const StoryPage = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  let components = []
  if(story.content.body) {
    components = story.content.body.map(blok => (
      <DynamicComponent blok={blok} story={pageContext} key={blok._uid} locale={pageContext.locale} />
    ))
  }

  let email = ""
  if(story && story.content && story.content.headline && story.content.excerpt) {
    email = linkShare(story);
  }
  let linkedin = linkedinShare(story)

  const showSocials = email ||  linkedin;
  let date = ""
  if(story.content.date) {
    let formDate = new Date(story.content.date.replace(/-/g, "/"));
    if(story.content.is_japanese) {
      date = formDate.toLocaleDateString('ja', {month : 'long', day: 'numeric', year: 'numeric'})
    } else if(location && location.href && location.href.includes("/fr/")) {
      date = formDate.toLocaleDateString('fr-FR', {month : 'long', day: 'numeric', year: 'numeric'})
    } else {
      date = formDate.toLocaleDateString('en-US', {month : 'long', day: 'numeric', year: 'numeric'})
    }
  }
  const imagePanelConfig = {
    bar_color_top: "white",
    bar_color_bottom: "light",
    bar_height_top: "50_percent",
    bar_height_bottom: "50_percent"
  }
  return (
    <LanguageProvider locale={pageContext.locale}>
      <Layout navigation={pageContext.navigation} location={location} settings={pageContext.globalSettings} locale={pageContext.locale}>
        <style>
          {`body {
            background-color: #f1f1f1;
          }`}
        </style>
        <Seo blok={story && story.content ? story : null} locale={pageContext.locale} location={location}/>
        <div className="story-hero">
          <MediaHero headline={story.content.headline} isNarrow={true} isStory={true} />
        </div>
        <div style={{backgroundColor: "#fff"}}>
          <NarrowContainer>
            <Container>
              <Row>
                {date ? <Col><div style={{fontWeight: "700"}}>{date}</div></Col> : null}
                {/* {date ? <Col><div style={{fontWeight: "700"}}><FormattedDate value={new Date(date)} year="numeric" month="long" day="2-digit" /></div></Col> : null} */}
                {showSocials ? (
                  <Col>
                    <div style={{textAlign: "right"}}>
                      {/* {twitter ? (
                        <a href="/" style={{marginLeft: 7}}>
                          <img src={TwitterIcon} alt="" />
                        </a>
                      ) : null} */}
                      {linkedin ? (
                        <a href={linkedin} style={{marginLeft: 7}} target="_blank">
                          <img src={LinkedinIcon} alt="" />
                        </a>
                      ) : null}
                      {/* {facebook ? (
                        <a href="/" style={{marginLeft: 7}}>
                          <img src={FacebookIcon} alt="" />
                        </a>
                      ) : null} */}
                      {email ? (
                        <a href={email} style={{marginLeft: 7}}>
                          <img src={LinkIcon} alt="" />
                        </a>
                      ) : null}
                    </div>
                  </Col>
                ) : null}
              </Row>
            </Container>
          </NarrowContainer>
          <br/>
        </div>
        {story.content.image ? (
          <PanelWrapper blok={imagePanelConfig}>
            <NarrowContainer>
              <div className="panel-radius">
              <StoryblokImage sbImageData={story.content.image} />
              </div>
            </NarrowContainer>
          </PanelWrapper>
        ) : null}
        <NarrowContainer>
          <div>
            { components }
          </div>
        </NarrowContainer>
      </Layout>
    </LanguageProvider>
  )
}

export default StoryPage